// @format

import './summernote-bs4.min.js';
import './notifier';
import {proceedErrorNotice, proceedSuccessNotice} from "./notifier";

export function destroySummernote(): void {
  $('.summernote').summernote('destroy');
}

export function initSummernote(): void {
  startSummernote();
  closeCodeViewOnSubmit();
  htmlChangeWatcher();
}

export const initOptOutSetter = () => {
  const summernote = $(".user_signature .summernote")
  summernote.on("summernote.change", function (e) {
    checkOptOutPresence()
  });
  $(document).on('click', '.set-opt-out-url', e => {
    e.preventDefault();
    summernote.summernote('code', `${summernote.summernote('code')}<br><div style=\'width: 600px; color: #9c9c94\'>Under the General Data Protection Regulation (GDPR) (EU) 2016/679, we have a legal duty to protect any information we collect from or about you. If you do not wish to be contacted by us again and thus removed from our registers, <a href=\'[opt-out-url]\' style=\'color: #9c9c94\'><u>click here</u></a> to opt out. We are committed to ensuring the security and protection of the personal information that we process, and to provide a compliant and consistent approach to data protection.`)
  });
}

export const checkOptOutPresence = () => {
  const summernoteContent = $(".user_signature .summernote").summernote('code').toString()
  const defineOptOut = summernoteContent.match(/((%5B)|\[)opt-out-url((%5D)|])/)
  const warning = $('.user_signature + .flow-info-box')
  if (defineOptOut && !!defineOptOut[0]){
    warning.fadeOut();
  } else {
    warning.fadeIn();
  }
}

document.addEventListener('summernote-xhr-loaded', function() {
  startSummernote();
});

function startSummernote(): void {
  $.summernote.dom.emptyPara = '<div><br></div>';
  $('.summernote').summernote({
    callbacks,
    popover,
    codeviewFilter: true,
    codeviewIframeFilter: true,
    shortcuts: true,
    minHeight: 260,
    fontNames: fontNames,
    toolbar,
  });
}

function formatOnPaste(
  e: JQueryEventObject,
  bufferText: string,
  elem: JQuery,
): void {
  if (
    bufferText.match(/[\[:]first-name[\]:]/) ||
    bufferText.match(/[\[:]company[\]:]/)
  ) {
    e.preventDefault();
    document.execCommand('insertText', false, bufferText);
  } else {
    document.execCommand('paste', true, bufferText);
    setTimeout(() => {
      formatHTML(elem);
    }, 200);
  }
}

function formatHTML(elem: JQuery): void {
  var data = new FormData();
  data.append('html', <string>elem.val());
  data.append('authenticity_token', $('[name="csrf-token"]').attr('content'));
  $.ajax({
    url: '/editor/format_html',
    cache: false,
    contentType: false,
    processData: false,
    data: data,
    type: 'PUT',
    success: function(html) {
      elem.summernote('code', html);
    },
  });
}

function uploadImage(image: string, elem: JQuery): void {
  var data = new FormData();
  data.append('image', image);
  data.append('authenticity_token', $('[name="csrf-token"]').attr('content'));
  $.ajax({
    url: '/editor/upload',
    cache: false,
    contentType: false,
    processData: false,
    data: data,
    type: 'put',
    success: function(event) {
      const image = $('<img>')
        .attr('src', event.url)
        .attr('alt', 'Photo or logo');
      elem.summernote('insertNode', image[0]);
      proceedSuccessNotice(event);
    },
    error: function (event){
      proceedErrorNotice(event)
    }
  });
}

function closeCodeViewOnSubmit(): void {
  $('.contains-summernote').submit(function(event) {
    $.each($('.summernote'), function() {
      if ($(this).summernote('codeview.isActivated')) {
        $(this).summernote('codeview.deactivate');
      }
    });
  });
}

function maybeHideSendEmailButton(elem: JQuery): void {
  if (elem.parents('.sequence_body').length > 0) {
    disableButton($('.initial-outreach .send-test-email'));
  } else if (elem.parents('.sequence_follow_up_1_body').length > 0) {
    disableButton($('.follow-up-1 .send-test-email'));
  } else if (elem.parents('.sequence_follow_up_2_body').length > 0) {
    disableButton($('.follow-up-2 .send-test-email'));
  } else if (elem.parents('.sequence_follow_up_3_body').length > 0) {
    disableButton($('.follow-up-3 .send-test-email'));
  } else if (elem.parents('.sequence_follow_up_4_body').length > 0) {
    disableButton($('.follow-up-4 .send-test-email'));
  } else if (elem.parents('.sequence_follow_up_5_body').length > 0) {
    disableButton($('.follow-up-5 .send-test-email'));
  }
}

function disableButton(elem: JQuery): void {
  elem.text('(Save required)');
  elem.attr('disabled', 'disabled');
  elem.attr('href', 'javascript:void(0)');
  elem.data('method', '');
  elem.addClass('disabled');
}

function htmlChangeWatcher(): void {
  $('.sequence_body .note-codable').bind('input propertychange', e => {
    maybeHideSendEmailButton($(e.currentTarget));
  });
}

const popover = <any>{
  image: [
    ['float', ['floatLeft', 'floatRight', 'floatNone']],
    ['remove', ['removeMedia']],
  ],
  link: [['link', ['linkDialogShow', 'unlink']]],
};

const callbacks = {
  onFocus() {
    this.inFocus = true;
  },
  onBlur() {
    this.inFocus = false;
  },
  onChange() {
    if (this.inFocus) {
      // https://github.com/summernote/summernote/issues/943
      maybeHideSendEmailButton($(this));
    }
  },
  onPaste(e) {
    const bufferText = (e.originalEvent || e).clipboardData.getData('Text');
    formatOnPaste(e, bufferText, $(this));
  },
  onImageUpload(image) {
    uploadImage(image[0], $(this));
  },
};

const fontNames = ['Arial', 'Helvetica', 'Times New Roman', 'Verdana'];

const toolbar = <any>[
  ['clear', ['clear']],
  ['style', ['bold', 'italic', 'underline']],
  ['font', ['fontname', 'fontsize', 'color']],
  ['insert', ['link', 'picture']],
  ['misc', ['codeview']],
];
