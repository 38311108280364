// @format

export function initFront(): void {
  if ($('.root.index').length) {
    pitchTextAnimation();
  }
  if ($('.step-num').length) {
    stepNumAnimation();
  }
  if ($('.cta.self-cta').length) {
    ctaAnimation();
  }
}

function pitchTextAnimation() {
  // differs between languages and needs to be static in order for
  // animation to work

  let h1Height = $(".pitch h1").height();
  $(".h1-container").css("height", h1Height + "px");

  $(".pitch h1").animate({ marginTop: "3px", opacity: 1 }, 400, function() {
    $(this).animate({ marginTop: "0px" }, 100 );
  });
}

function ctaAnimation() {
  let delayBeforeAnimationStart = 300;
  let elem = '.cta.self-cta'
  $(window).scroll(function() {
    if (isScrolledIntoView(elem)) {
      setTimeout(function() {
        setTimeout(function() {
          $(elem).animate({ opacity: 1 }, 300);
        });
      }, delayBeforeAnimationStart);
    }
  });
}

function stepNumAnimation() {
  let elem = '.step-num'

  if (isScrolledIntoView(elem)) {
    startStepNumAnimation(elem);
  }

  $(window).scroll(function() {
    if (isScrolledIntoView(elem)) {
      startStepNumAnimation(elem);
    }
  });
}

function startStepNumAnimation(elem) {
  let delayBeforeAnimationStart = 300;
  let delayBetweenNumAnimations = 200;
  setTimeout(function() {
    $(elem).each(function(i) {
      let t = $(this);
      setTimeout(function() {
        t.animate({ opacity: 1 }, 300);
      }, delayBetweenNumAnimations*i);
    });
  }, delayBeforeAnimationStart);
}

function isScrolledIntoView(elem: string) : boolean {
  var docViewTop = $(window).scrollTop();
  var docViewBottom = docViewTop + $(window).height();

  var elemTop = $(elem).offset().top;
  var elemBottom = elemTop + $(elem).height();

  return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}
