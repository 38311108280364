export function initToggle() {
	document.querySelectorAll("[data-toggle-id]").forEach(function (button) {
		if (!button.getAttribute("data-click-added")) {
			button.addEventListener("click", function () {
				var targetId = button.getAttribute("data-toggle-id");
				var targetElement = document.getElementById(targetId);
				if (targetElement) {
					targetElement.style.display =
						targetElement.style.display === "none" ? "block" : "none";
				}
			});
			button.setAttribute("data-click-added", "true");
		}
	});
}
