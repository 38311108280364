// @format

import {setCookie} from './cookies';

export function initHomeBanner() {
  $(document).on('click', '.home-banner-close', e => {
    let homeBanner = $('.home-banner-block');
    homeBanner.css('display', 'none');
    $('body').removeClass('with-home-banner');
    $('main.app').addClass('fixed-top-header');
    $('.top-header').addClass('fixed');
    setCookie('homeBannerVersion', homeBanner.data('home-banner-version'), 999);
  });
}
