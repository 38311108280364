// @format

export function initHeader(): void {
  burgerClickEvents();
  initHeaderScroll();
}

function burgerClickEvents(): void {
  $('.burger a').on('click', 'i.fa-bars', () => {
    updateBurger('flex', 'none', 'fa-bars', 'fa-times');
  });

  $('.burger a').on('click', 'i.fa-times', () => {
    updateBurger('none', 'block', 'fa-times', 'fa-bars');
  });
}

function updateBurger(
  newDisplay: string,
  homeBannerDisplay: string,
  removeClass: string,
  addClass: string,
): void {
  $('.header-menu-mobile').css('display', newDisplay);
  $('.home-banner').css('display', homeBannerDisplay);
  $('.burger i')
    .removeClass(removeClass)
    .addClass(addClass);
}

function initHeaderScroll(): void {
  if ($('.top-header').length) {
    var elementPosition = $('.top-header').offset();

    $(window).scroll(function() {
      if ($('body.with-home-banner').length) {
        if ($(window).scrollTop() > elementPosition.top) {
          $('main.app').addClass('fixed-top-header');
          $('.top-header').addClass('fixed');
        } else {
          $('main.app').removeClass('fixed-top-header');
          $('.top-header').removeClass('fixed');
        }
      }
    });
  }
}
