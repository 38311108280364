export function initNotes(): void {
  $(document).off('click', '.notes-button').on('click', '.notes-button', e => {
    const botsProspectId = $(e.currentTarget).data('bots-prospect-id');
    $('.notes-' + botsProspectId).toggle('fast');
  });

  $(document).on("ajax:success", ".write-note-form", event => {
    const value = event.target.querySelector('textarea[name="bots_prospect[notes]"]').value;
    const botsProspectId = event.target.id.replace("edit_bots_prospect_", "")
    $(".notes-" + botsProspectId).hide()
    if(value) {
      $(".notes-button-" + botsProspectId).text("Notes").addClass('create');
    } else {
      $(".notes-button-" + botsProspectId).text("Write a note").removeClass('create');
    }
  })
}
