// @format

import { Turbo } from '@hotwired/turbo-rails';

export function initDisconnectMailListener(): void {
  $(document).on('click', '.disconnect-mail', e => {
    const t = $(e.currentTarget);
    if (confirm('Are you sure you? This will log you out, and no Qualifier emails may be sent until you log in again.')) {
      fetch('/mail/disconnect', {
        method: 'delete',
        credentials: 'same-origin',
        headers: {
          'X-CSRF-TOKEN': document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
      }).then(_ => Turbo.visit('/'));
    }
  });
}
