function camelCaseToWords(s) {
	const result = s.replace(/([A-Z])/g, " $1").toLowerCase();
	return result.charAt(0).toUpperCase() + result.slice(1);
}

function parseExistingData(json) {
	const data = JSON.parse(json);

	if (data.version == 2) {
		if (!data.skills) {
			data.skills = [];
		}

		return data;
	}

	const transformKeys = ["job_titles", "locations", "schools", "companies"];

	const oldLocationFilterNames = {
		current: "currentLocation",
		currentOrOpenToRelocate: "preferredOrCurrent",
		openToRelocateOnly: "preferredNotCurrent",
	};

	const transform = (obj) =>
		obj.items.map((item) => ({
			text: item,
			filter: obj.filter,
		}));

	const result = Object.keys(data).reduce((acc, key) => {
		if (transformKeys.includes(key) && !Array.isArray(data[key])) {
			acc[key] = transform(data[key]);

			if (key === "locations") {
				acc[key] = acc[key].map((location) => ({
					...location,
					filter: oldLocationFilterNames[location.filter] || location.filter,
				}));
			}
		} else {
			acc[key] = data[key];
		}
		return acc;
	}, {});

	if (!result.skills) {
		result.skills = [];
	}

	return result;
}

export function initSearchPreviews() {
	const searchPreviewId = $("#search_preview_id").val();
	const existingData = $("#search_preview_data").val();
	// const statusContainer = document.getElementById("search-preview-status");

	// if (statusContainer) {
	//   setInterval(function() {
	//     const event = new Event("refresh");
	//     console.log("Refreshing status container");
	//     statusContainer.dispatchEvent(event);
	//   }, 5000);
	// }

	let data = existingData
		? parseExistingData(existingData)
		: {
				job_titles: [],
				locations: [],
				companies: [],
				schools: [],
				skills: [],
				years_of_experience: { min: null, max: null },
				years_of_graduation: { min: null, max: null },
				keywords: "",
			};

	const renderAddedItems = () => {
		$(".added-items").empty();

		data.job_titles.forEach(function (jobTitle, index) {
			const element = $("<div class='added-item'>")
				.text(jobTitle.text + " (" + camelCaseToWords(jobTitle.filter) + ")")
				.attr("data-index", index);
			const close = $("<div>").text("x").addClass("close-button");
			element.append(close);

			element.click(function () {
				const indexToRemove = $(this).data("index");
				data.job_titles.splice(indexToRemove, 1);
				renderAddedItems();
			});

			$("#add-job-titles .added-items").append(element);
			$("input#job-title").val("");
		});

		data.locations.forEach(function (location, index) {
			const filterNames = {
				currentLocation: "Current",
				preferredNotCurrent: "Open to relocate only",
				preferredOrCurrent: "Current or open to relocate",
			};
			const element = $("<div>")
				.text(location.text + " (" + filterNames[location.filter] + ")")
				.attr("data-index", index);
			const close = $("<div>").text("x").addClass("close-button");
			element.append(close);

			element.click(function () {
				const indexToRemove = $(this).data("index");
				data.locations.splice(indexToRemove, 1);
				renderAddedItems();
			});

			$("#add-locations .added-items").append(element);
		});

		data.companies.forEach(function (company, index) {
			const close = $("<div>").text("x").addClass("close-button");
			const element = $("<div>")
				.text(company.text + " (" + camelCaseToWords(company.filter) + ")")
				.attr("data-index", index);
			element.append(close);

			element.click(function () {
				const indexToRemove = $(this).data("index");
				data.companies.splice(indexToRemove, 1);
				renderAddedItems();
			});

			$("#add-companies .added-items").append(element);
		});

		data.schools.forEach(function (text, index) {
			const element = $("<div>").text(text).attr("data-index", index);
			const close = $("<div>").text("x").addClass("close-button");
			element.append(close);

			element.click(function () {
				const indexToRemove = $(this).data("index");
				data.schools.splice(indexToRemove, 1);
				renderAddedItems();
			});

			$("#add-schools .added-items").append(element);
		});

		const skills = data.skills || [];

		skills.forEach(function (text, index) {
			const element = $("<div>").text(text).attr("data-index", index);
			const close = $("<div>").text("x").addClass("close-button");
			element.append(close);

			element.click(function () {
				const indexToRemove = $(this).data("index");
				data.skills.splice(indexToRemove, 1);
				renderAddedItems();
			});

			$("#add-skills .added-items").append(element);
		});

		if (data.keywords.length) {
			const element = $("<div>").text(data.keywords);

			element.click(function () {
				$("#add-keywords .add-input").show();
				$("#add-keywords .added-items").empty();
				$("#add-keywords textarea").focus();
			});

			$("#add-keywords .added-items").append(element);
		}

		if (data.years_of_experience.min && data.years_of_experience.max) {
			const from = data.years_of_experience.min;
			let to = data.years_of_experience.max;
			const element = $("<div>").text(`${from} - ${to}`);
			const close = $("<div>").text("x").addClass("close-button");
			element.append(close);

			element.click(function () {
				data.years_of_experience = { min: null, max: null };
				$("#add-years-of-experience .added-items").empty();
				$("#add-years-of-experience .add-button").show();
			});

			$("#add-years-of-experience .added-items").append(element);
		}

		if (data.years_of_graduation.min && data.years_of_graduation.max) {
			const from = data.years_of_graduation.min;
			let to = data.years_of_graduation.max;
			const element = $("<div>").text(`${from} - ${to}`);
			const close = $("<div>").text("x").addClass("close-button");
			element.append(close);

			element.click(function () {
				data.years_of_graduation = { min: null, max: null };
				$("#add-years-of-graduation .added-items").empty();
				$("#add-years-of-graduation .add-button").show();
			});

			$("#add-years-of-graduation .added-items").append(element);
		}
	};

	if (existingData) {
		renderAddedItems();
	}

	$(document).ready(function () {
		$(".search-button").click(function () {
			var targetingId = $("#targeting_id").val();
			var url = `/targetings/${targetingId}/search_previews`;

			if (searchPreviewId) {
				url = `/targetings/${targetingId}/search_previews/${searchPreviewId}`;
			}

			$.ajax({
				url,
				type: searchPreviewId ? "PATCH" : "POST",
				contentType: "application/json",
				data: JSON.stringify({ data }),
				success: function (response) {
					if (response.errors) {
						alert(response.errors.join("\n"));
					} else if (response.id) {
						window.location.href = `/targetings/${targetingId}/search_previews/${response.id}`;
					}
				},
				error: function (error) {
					console.log(error);
				},
			});
		});

		$(".add-container .add-button").click(function () {
			const container = $(this).parent();
			$(this).hide();
			const input = $(container).find(".add-input");
			input.show();

			$(document).mousedown(function (e) {
				if (
					[
						"add-keywords",
						"add-years-of-experience",
						"add-years-of-graduation",
					].includes(container.attr("id"))
				) {
					return;
				}

				if (!container.is(e.target) && container.has(e.target).length === 0) {
					$(this).show();
					$(container).find(".add-input").hide();
					$(container).find(".add-button").show();
				}
			});
		});

		const saveYearsOfExperience = () => {
			var min = $("#min-years-of-experience").val() || 0;
			var max = $("#max-years-of-experience").val() || 30;
			data.years_of_experience.min = min;
			data.years_of_experience.max = max;
			$("#add-years-of-experience .add-input").hide();
		};

		const saveYearsOfGraduation = () => {
			var min = $("#min-years-of-graduation").val() || 0;
			var max = $("#max-years-of-graduation").val() || 30;
			data.years_of_graduation.min = min;
			data.years_of_graduation.max = max;
			$("#add-years-of-graduation .add-input").hide();
		};

		$(".add-input input").on("keypress", function (e) {
			const id = $(this).closest(".add-container").attr("id");
			const textElem = $(this)
				.closest(".add-container")
				.find(".add-input input[type='text']");

			if (e.which == 13) {
				e.preventDefault();

				if (id === "add-job-titles") {
					var text = $(textElem).val();
					var filter = $('input[name="job-status"]:checked').val();
					data.job_titles = [...data.job_titles, { text, filter }];
				} else if (id === "add-locations") {
					var text = $(textElem).val();
					var filter = $('input[name="location-status"]:checked').val();
					data.locations = [...data.locations, { text, filter }];
				} else if (id === "add-companies") {
					var text = $(textElem).val();
					var filter = $('input[name="company-status"]:checked').val();

					if (data.companies.length < 20) {
						data.companies = [...data.companies, { text, filter }];
					} else {
						alert("Maximum 20 companies");
					}
				} else if (id === "add-schools") {
					var text = $(textElem).val();
					data.schools = [...data.schools, text];
				} else if (id === "add-years-of-experience") {
					saveYearsOfExperience();
				} else if (id === "add-years-of-graduation") {
					saveYearsOfGraduation();
				} else if (id === "add-skills") {
					console.log("DDD", data);
					data.skills = [...data.skills, $(textElem).val()];
				} else {
					alert("Not implemented yet");
				}

				renderAddedItems();

				if (
					id !== "add-years-of-experience" &&
					id !== "add-years-of-graduation"
				) {
					$(textElem).val("");
				}
			}
		});

		$("#add-years-of-experience .save-button").click(function () {
			saveYearsOfExperience();
			renderAddedItems();
		});

		$("#add-years-of-graduation .save-button").click(function () {
			saveYearsOfGraduation();
			renderAddedItems();
		});

		$(".add-input textarea").on("keypress", function (e) {
			if (e.which == 13) {
				e.preventDefault();
				const container = $(this).closest(".add-container");
				const text = $(this).val();
				data.keywords = text;

				$(container).find(".add-input").hide();

				if (text.length) {
					$(container).find(".add-button").hide();
				} else {
					$(container).find(".add-button").show();
				}

				renderAddedItems();
				this.blur();
			}
		});
	});
}
