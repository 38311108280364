// @format

import { Turbo } from '@hotwired/turbo-rails';


export function initDestroyBotListener(): void {
  $(document).on('click', '.targeting-info', e => {
    const t = $(e.currentTarget);
    e.preventDefault();
    e.stopPropagation();
    t.closest('a').removeClass('active');
    false;
  });
  $(document).on('click', '.destroy-bot', e => {
    const t = $(e.currentTarget);
    if (confirm('Are you sure you want to delete this bot?')) {
      t.html('Deleting ...');
      t.prop('disabled', true);
      fetch('/bots/' + t.data('id') + '/delete', {
        method: 'delete',
        credentials: 'same-origin',
        headers: {
          'X-CSRF-TOKEN': document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
      }).then(_ => Turbo.visit('/'));
    }
  });
}

export function initShowSequences(): void {
  $(document).on("click", ".toggle-sequences-icon", e => {
    e.preventDefault()
    $(e.target).hide()
  })
}
