declare const window: any;

const injectScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', resolve);
    script.addEventListener('error', e => reject(e.error));
    document.head.appendChild(script);
  });
}

function showHubspot(isLoaded) {
  const widget = window.HubSpotConversations.widget

  if (widget) {
    $("#hubspot-messages-iframe-container").css('visibility','visible')

    if (isLoaded) {
      widget.open()
    } else {
      widget.load({widgetOpen: true})
    }

    widgetStateObserver()
  } else {
    console.log('Hubspot is loading')
  }
}

export function initHubspot(): void {
  let scripts, script;

  scripts = document.querySelectorAll('script[data-loader="hs-scriptloader"]');
  for (let script of scripts) {
    script.remove();
  }
  script = document.getElementById('hs-analytics');
  if (script != undefined) {
    script.remove();
  }
  window.hubspot_live_messages_running = undefined
  window.hubspot_loaded = false
  if ($('body').data('load-script') == true) {
    const elem = $(".hubspot-chat")

    elem.on("click", function(event) {
      event.preventDefault()

      if ($("#hubspot-messages-iframe-container").length) {
        showHubspot(true)
      } else {
        const originalHtml = elem.html()
        elem.text("Loading")

        const observer = new MutationObserver((mutations) => {
          mutations.forEach(mutation => {
            if ($("#hubspot-messages-iframe-container").length) {
              observer.disconnect()
              elem.html(originalHtml)
              showHubspot(false)
            }
          })
        })

        observer.observe(document.body, {childList: true})
      }
    })

    injectScript('https://js.hs-scripts.com/3850222.js')
  }
}

const widgetStateObserver = () => {
  const element = document.querySelectorAll(".shadow-container")[0];
  const jqElement = $(".shadow-container")
  const attrObserver = new MutationObserver((mutations) => {
    mutations.forEach(mu => {
      if (mu.type !== "attributes" && mu.attributeName !== "class") return;
      if(!jqElement.hasClass('active')){
        $("#hubspot-messages-iframe-container").css('visibility','hidden')
        attrObserver.disconnect();
      }
    });
  });
  attrObserver.observe(element, {attributes: true})
}
