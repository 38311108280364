export function initQlfrModal(): void {
  $(".qlfr-show-modal").click(function(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    $(".qlfr-modal-container").addClass('active')
  });

  $(".qlfr-show-the-modal").on('click', function(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    console.log( $(this).data('modal'))
    $("#"+ $(this).data('modal')).addClass('active')
  });

  $(".qlfr-show-closest-modal").on('click', function(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    $(this).parent().find('.qlfr-modal-container').addClass('active')
    sendTitleListener(event.currentTarget);
  });

  $(".qlfr-modal-container").click(function(event) {
    if ($(event.target).is(".qlfr-modal-container")) {
      closeBotModal();
    }
  });

  $(".qlfr-modal-close").click(function(event) {
    closeBotModal();
  });

  $(document).on('ajax:success', '.upload-to-jobylon-form', event => {
    closeBotModal();
    $(event.target).parents(".prospect").find(".push-to-jobylon").replaceWith("<i class='fal fa-external-link'></i> Uploaded to Jobylon")
  });

  $(document).on('ajax:error', '.upload-to-jobylon-form', event => {
    alert("There was an error pushing to Jobylon")
    closeBotModal();
  });
}

function closeBotModal(): void {
  $(".qlfr-modal-container").removeClass('active')
}

const sendTitleListener = (currentTarget) => {
  $(".send-title").on('click',(e) => {
    e.preventDefault();
    const form = $(e.currentTarget.closest('form'))
    const selected = form.find('.selectpickerr option:selected')
    const gender = selected.val()
    const display = selected.text()
    $.ajax({
      url: `/bots_prospect/${form.data('id')}/${gender}`,
      data: {
        authenticity_token: $('[name="csrf-token"]').attr('content'),
      },
      type: 'PATCH',
      success: function() {
        closeBotModal();
        $(currentTarget).text(`[${display}]`);
      },
    });
  })
}
