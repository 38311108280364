// @format

export function initProspectToggle(): void {
  $(document).on('change', '.bots-prospect-toggle', e => {
    const t = $(e.currentTarget);

    $.ajax({
      url: '/bots/' + t.data('bot-id') + '/prospects/' + t.data('id'),
      contentType: 'application/json; charset=UT-8',
      dataType: 'json',
      data: JSON.stringify({
        authenticity_token: $('[name="csrf-token"]').attr('content'),
        unreviewed: t.hasClass("reviewed"),
        bots_prospect: {
          rejected_at: !t.prop('checked') && new Date()
        },
      }),
      error: function(jqXHR, exception) {
        t.prop('checked', !t.prop('checked'));
      },
      success: () => {
        const reject_button = t
          .closest('tr')
          .find('.reject-reasons-wrap, .reject-prospect-button');
        if (t.prop('checked')) {
          reject_button.addClass('hide');
        } else {
          reject_button.removeClass('hide');
        }
      },
      type: 'PATCH',
    });
  });
}

export const initRejectReasonListener = () => {
  $(document).on('click', '.reject-reasons a', e => {
    e.preventDefault();
    const link = $(e.currentTarget);
    const wrapper = link.closest('.reject-reasons-wrap');
    if (link.hasClass('other')) {
      wrapper.find('.reject-reasons').addClass('hidden')
      wrapper.find('.reject-prospect-button').removeClass('hidden')
      initAddToBlockListener(wrapper);
    } else {
      sendRejectReason(`/add_reject_reason/${wrapper.data('id')}/${link.data('reason')}`, link, wrapper);
    }
  });
}

const initAddToBlockListener = (wrapper) => {
  wrapper.find('.reject-prospect-button').on('click', function (e) {
    e.preventDefault();
    const t = $(this)
    sendRejectReason(`/bots/${t.data('bot-id')}/prospects/${t.data('id')}/add_to_company_blocklist`, t, wrapper);
    $(this).text('Company added to block list')
  })
}

const sendRejectReason = (url, link, wrapper) => {
  $.ajax({
    url: url,
    data: {
      authenticity_token: $('[name="csrf-token"]').attr('content'),
    },
    type: 'PATCH',
    success: function() {
      link.addClass('checked')
      if (wrapper.hasClass("reject-reasons-wrap")){
        // wrapper.addClass('hide')
      }
    },
  });
}

export function initEditProspectListener(): void {
  $(document).on('click', '.edit-bots-prospect', e => {
    const botsProspectId = $(e.currentTarget).data('bots-prospect-id');
    $('.edit-form-' + botsProspectId).toggle('fast');
  });
  $(document).on('click', '.customize-message-button', e => {
    const botsProspectId = $(e.currentTarget).data('bots-prospect-id');
    $('.custom-message-' + botsProspectId).toggle('fast');
  });
  $(document).on('click', '.delete-customized-message-button', e => {
    const customMessageId = $(e.currentTarget).data('custom-message-id');
    if (confirm('Are you sure?')) {
      fetch('/custom_messages/' + customMessageId + '/delete', {
        method: 'delete',
        credentials: 'same-origin',
        headers: {
          'X-CSRF-TOKEN': document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
      }).then(_ => location.reload());
    }
  });
}

export function initPhoneCompletedListener(): void {
  $(document).on('click', '.phone-completed', e => {
    const target = $(e.currentTarget);
    target.css('display', 'none');
    const botsProspectId = target.data('bots-prospect-id');
    $('.phone-completed-triage-' + botsProspectId).show();
  });
}

export function initEmailsReceived(): void {
  $(document).on('change', '#emails_received', e => {
    const target = $(e.currentTarget);
    window.location.href =
      '/bots/' +
      target.data('botId') +
      '/prospects/phone/?emails_received=' +
      $('#emails_received option:selected').val();
  });
}

export function initReApproachedBtn(): void {
  $(".manually-unblocked").on('click', function () {
    const thisObj = $(this)
    thisObj.closest('td').find('.manually-re-unblocked').removeClass("active")
    thisObj.closest('td').find('.manually-unblocked').addClass("active");
  })
  $(".manually-re-unblocked").on('click', function () {
    const thisObj = $(this)
    thisObj.closest('td').find('.manually-re-unblocked').addClass("active")
    thisObj.closest('td').find('.manually-unblocked').removeClass("active");
  })
}

export function initCheckedBotsProspect(): void {
  $(".checked-bots-prospect").on('ajax:success', function(event) {
    const target = event.currentTarget;
    const detail = event.detail[0]
    const checkedBy = detail.checked_by
    const checkedAt = detail.checked_at
    $(target).replaceWith(`<div class="intro--reviewed-by">Reviewed by <strong>${checkedBy}</strong> at ${checkedAt}</div>`)
  })

  $("#check-all-prospects").on("ajax:success", function(event) {
    const target = event.currentTarget;
    const detail = event.detail[0]
    const checkedBy = detail.checked_by
    const checkedAt = detail.checked_at
    $(target).addClass("disabled")
    $(".checked-bots-prospect").replaceWith(`<div class="intro--reviewed-by">Reviewed by <strong>${checkedBy}</strong> at ${checkedAt}</div>`)
  })
}

export function initBotProspectHistory(): void {
  $(".history-button").on("click", function(event) {
    const target = event.currentTarget;
    const botsProspectId = target.dataset.botsProspectId;
    const historyElem = $(`.bot-prospect-history.bot-prospect-${botsProspectId}`);
    historyElem.toggle();
  });
}

export function initPersonalizedMessage(): void {
  const $sequenceBtns = $('input[name="custom_message[sequence_id]"]');

  $sequenceBtns.each(function() {
    $(this).off('click').on('click', function() {
      const $form = $(this).closest('form');
      $form.find('#custom_message_subject').val($(this).data('subject'));
      $form.find('#custom_message_body').summernote("code", $(this).data('body'));
    });
  });
}
